import api from "./api"

const getUsuarios = async () => {
    try {
        const resultado = await api.get("/usuarios")
        console.log("{ getUsuarios }", resultado)
        const usuarios = resultado.data
        return usuarios
    } catch (error) {
        throw error
    }
}

const putUsuario = async (id, data) => {
    try {
        const resultado = await api.put(`/usuarios/${id}`, data)
        const usuarios = resultado.data
        console.log("putUsuario result", usuarios)
        return usuarios
    } catch (error) {
        throw error
    }
}

const postUsuario = async (data) => {
    try {
        const resultado = await api.post("/usuarios", data)
        const usuarios = resultado.data
        console.log("postUsuarios result", usuarios)
        return usuarios
    } catch (error) {
        throw error
    }
}

const deleteUsuario = async (id) => {
    try {
        const resultado = await api.delete(`/usuarios/${id}`)
        const usuarios = resultado.data
        return usuarios
    } catch (error) {
        throw error
    }
}

export { getUsuarios, postUsuario, putUsuario, deleteUsuario }