import React, { useContext, memo } from "react"

import classnames from "classnames"
import NavContext from "../../../../../../contexts/NavContext"

const TabNav = ({ tabs }) => {
    const { tabSelected, setTabSelected } = useContext(NavContext)

    return (
        <div className="nav">
            {tabs && Object.keys(tabs).map((tabKey, index) => (
                <div
                    key={index}
                    className={classnames({
                        navLink: true,
                        active: tabSelected == index,
                    })}
                    onClick={() => setTabSelected(index)}
                >
                    <span>{tabs[tabKey].title}</span>
                </div>
            ))}
        </div>
    )
}

export default memo(TabNav)