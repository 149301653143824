import React from "react"

import { NavProvider } from "../../../../../contexts/NavContext"

import TabNav from "./TabNav"
import TabContent from "./TabContent"

export default function Nav({ tabs }) {

    return (
        <NavProvider>
            <TabNav tabs={tabs} />
            <TabContent tabs={tabs} />
        </NavProvider>
    )
}