import { useState } from "react"

import { Tooltip } from "reactstrap"

export default function TooltipHelp({ text, placement = "top" }) {
    const [show, setShow] = useState(false)
    const [id] = useState(`tooltip-${(Math.random() * 10000000000000).toFixed(0)}`)

    return (
        <>
            <Tooltip
                placement={placement}
                isOpen={show ? true : false}
                target={id}
                toggle={() => {
                    setShow(!show)
                }}
            >
                {text}
            </Tooltip>

            <i className="bx bx-info-circle" id={id}></i>
        </>
    )
}