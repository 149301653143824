import { Spinner } from "reactstrap"

import "./style.scss"

export default function Carregando({ texto = "Carregando...", type, color = "dark" }) {
    return (
        <div className="carregando">
            <Spinner type={type} className="m-1 carregando-spinner" color={color} />
            {texto && <h3>{texto}</h3>}
        </div>
    )
}