import React, { useState, createContext } from 'react'

const NavContext = createContext({})

export const NavProvider = ({ children }) => {
    const [tabSelected, setTabSelected] = useState(0)

    return (
        <NavContext.Provider value={{
            tabSelected, setTabSelected
        }}>
            {children}
        </NavContext.Provider>
    )
}

export default NavContext
