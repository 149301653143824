import React, { useEffect } from "react"

import { logout } from '../../services/authService'

const Logout = props => {
  useEffect(() => {
    logout()
  })

  return <></>
}

export default Logout